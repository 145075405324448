import CachedApi from "@/commons/CachedApi.js";
import Http from "@/commons/Http.js";

const path = 'api/v2/regras-estaduais';

export default {

  async getCustaAbatimentoRO(protocolo){
    return CachedApi.getCached('ro-custa-abatimento-'+protocolo, async () => {
      return Http.get(`${path}/RO/abatimento/${protocolo}`);
    });
  },

  async getSaldoProvisionadoSP(protocolo){
    return CachedApi.getCached('sp-saldo-provisionado-'+protocolo, async () => {
      return Http.get(`${path}/RO/abatimento/${protocolo}`);
    });
  },

  async gerarDajeBahia(protocolo, dto){
    return Http.post(`${path}/BA/${protocolo}/daje`, dto);
  },

  async capturarDajesAtoBA(ato){
    return Http.get(`${path}/BA/dajes-ato/${ato}`);
  },

  async importarSelosTJMG(file) {

    const formData = new FormData();
    formData.append("file", file);

    let config = {
      timeout: 0,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    return Http.post(`${path}/MG/importar-selos-criptografados`, formData, config);
  },

  async solicitarSelosTJMT(quantidade){
    return Http.post(`${path}/MT/solicitar-selo/${quantidade}`);
  },

  async consultarEntregaLoteTJMT(lote) {
    return Http.post(`${path}/MT/consultar-entrega-lote/${lote}`);
  }
}
