import Http from "@/commons/Http";
import FrontBusiness from "@/business/FrontBusiness";

const path = 'api/selo';

export default {

  async estoque(){
    return Http.get(`${path}/estoque`);
  },

  async pesquisarEntidades(){
    return Http.get(`${path}/entidades`);
  },

  async protocoloPossuiSelosNaoEnviados(protocolo){
    return Http.get(`${path}/protocolo/${protocolo}/possui-selos-nao-enviados`);
  },

  async inutilizar(codigo, dto){
    return Http.post(`${path}/inutilizar/${codigo}`,  dto);
  },

  async importarRetornoTJ(file) {

    const formData = new FormData();
    formData.append("file", file);

    let config = {
      timeout: 0,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    return Http.post(`${path}/importar-retorno-tj`, formData, config);
  },

  async importarSelosXML(file) {

    const formData = new FormData();
    formData.append("file", file);

    let config = {
      timeout: 0,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    return Http.post(`${path}/importar-selos-xml`, formData, config);
  },

  async importarSelosTJ(){
    return Http.get(`${path}/importar-selos-tj`,  {timeout : 0});
  },

  async lotesAbertos(){
    return Http.get(`${path}/lotes-abertos`,  {timeout : 0});
  },

  async getConsumoMedio(){
    return Http.get(`${path}/consumo-medio`,  {timeout : 0});
  },

  async consultarTj(codigo){
    return Http.get(`${path}/consultar-tj/${codigo}`,  {timeout : 0});
  },

  async consultarBase(codigo){
    return Http.get(`${path}/consultar-base/${codigo}`,  {timeout : 0});
  },

  async consultarSelo(id){
    return Http.get(`${path}/consultar-selo/${id}`,  {timeout : 0});
  },

  async buscarPorCodigos(codigos, tipo) {
    return await Http.post(`${path}/consultar-base/codigos/${tipo}`, codigos);
  },

  async consultarLoteTj(id){
    return Http.get(`${path}/consultar-lote-tj/${id}`,  {timeout : 0});
  },

  async consultarLoteBase(id){
    return Http.get(`${path}/consultar-lote-base/${id}`,  {timeout : 0});
  },

  async ultimosLotes(limit){
    return Http.get(`${path}/ultimos-lotes/${limit || 10}`,  {timeout : 0});
  },

  async verificarLotesTJ(){
    return Http.post(`${path}/verificar-lotes-tj`, null, {timeout : 0});
  },

  async enviarSelos(codigo){
    return Http.post(`${path}/enviar-selos${codigo ? '/'+codigo : ''}`, null, {timeout : 0});
  },

  async confirmarEnvioSelosManualmente(data, siglaEstado){
    return Http.post(`${window.server.API}/api/v2/regras-estaduais/${siglaEstado}/confirmar-envio-selos/${data}`)
  },

  async redimensionarLote(id){
    return Http.post(`${path}/redimensionar-lote/${id}`, null, {timeout : 0});
  },

  async cancelarLote(id){
    return Http.post(`${path}/cancelar-lote/${id}`, null, {timeout : 0});
  },

  async baixarLote(id){
    return Http.post(`${path}/baixar-lote/${id}`, null, {timeout : 0});
  },

  async selarPrenotacao(prenotacaoId){
    return Http.post(`${path}/selar-prenotacao/${prenotacaoId}`, null, {timeout : 0});
  },

  async selarCustaAdicional(id){
    return Http.post(`${path}/selar-custa-adicional/${id}`, null, {timeout : 0});
  },

  async selarCertidao(certidaoId){
    return Http.post(`${path}/selar-certidao/${certidaoId}`, null, {timeout : 0});
  },

  async selarCertidaoProtocolo(certidaoId){
    return Http.post(`${path}/${certidaoId}/selar-certidao`, null, {timeout : 0});
  },

  async selarAto(atoId){
    return Http.post(`${path}/selar-ato/${atoId}`, null, {timeout : 0});
  },

  async selarProtocoloSimplificado(protocoloId){
    return Http.post(`${path}/selar-protocolo/${protocoloId}/simplificado`, null, {timeout : 0});
  },

  async consultarStatusSelagem(tipo, id){
    return Http.get(`${path}/selando-${tipo}/${id}`);
  },

  async getSelosPendentesCertidao(id){
    return Http.get(`${path}/selos-pendentes-certidao/${id}`);
  },

  async getSelosPendentesPrenotacao(id){
    return Http.get(`${path}/selos-pendentes-prenotacao/${id}`);
  },

  async getSelosPendentesProtocolo(id){
    return Http.get(`${path}/selos-pendentes-protocolo/${id}`);
  },

  async getSelosPendentesBuscas(id){
    return Http.get(`${path}/selos-pendentes-buscas/${id}`);
  },

  async getSelosPendentesCustasAdicionais(id){
    return Http.get(`${path}/selos-pendentes-custa-adicional/${id}`);
  },

  async getSelosPendentesAto(id){
    return Http.get(`${path}/selos-pendentes-ato/${id}`);
  },

  async getSeloProtocolo(id){
    return Http.get(`${path}/selo-protocolo/${id}`);
  },

  async getSeloProtocoloCertidao(id){
    return Http.get(`${path}/selo-protocolo-certidao/${id}`);
  },

  async getSeloCertidao(id){
    return Http.get(`${path}/selo-certidao/${id}`);
  },

  async resetarSelo(id){
    return Http.post(`${path}/reset/${id}`);
  },

  async reativar(id){
    return Http.post(`${path}/reativar/${id}`);
  },

  async desvincularSelo(id){
    return Http.post(`${path}/desvincular/${id}`);
  },

  async retificarSelo(id, dto = {}, certidao = false){
    return Http.post(`${path}/retificar${certidao ? '-certidao' : ''}/${id}`, dto);
  },

  async retificarSeloCustaAdicional(id, dto){
    return Http.post(`${path}/retificar-custa-adicional/${id}`, dto);
  },

  async retificarSeloLote(dto = {}){
    return Http.post(`${path}/retificar-lote`, dto);
  },

  async getSelos(tipo, id, estado){
    return Http.get(`${path}/selos-${tipo}/${id}`).then(l => l.map(c => {
      c.fundos = JSON.parse(c.fundos || '{}');

      if(estado == "SC"){
        c.descricao = c.codigoAto == "1" ? "Selo Isento" : "Selo Normal";
      }

      return c;
    }));
  },

  getStatusSelo() {
    return [
      {id: 'PENDENTE', nome: 'Pendente'},
      {id: 'DEVOLVIDO', nome: 'Devolvido'},
      {id: 'CANCELADO', nome: 'Cancelado'},
      {id: 'USADO', nome: 'Usado'},
      {id: 'VERIFICAR', nome: 'Verificar'},
    ]
  },

  getTiposSelo(estado) {
    let lista = [
      {id: '', nome: 'Todos'},
      {id: 'ISENTO', nome: 'Isentos'},
      {id: 'NAO_ISENTO', nome: 'Não Isentos'}
    ]

    if(estado === 'SC'){
      lista.push({id:'RETIFICADO',nome: 'Retificados'});
      lista.push({id:'RETIFICADOR',nome: 'Retificadores'});
    }
    return lista;
  },

  async pagination(sort, direction, page, size, filters = {}) {
    return Http.post(`${path}/pagination?sort=${sort || 'sl.solicitacao'},${direction || 'asc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async paginationPendentes(sort, direction, page, size, filters = {}) {
    return Http.post(`${path}/pagination/pendentes?sort=${sort || 's.tentativa'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async paginationErros(sort, direction, page, size, filters = {}) {
    return Http.post(`${path}/pagination/erros?sort=${sort || 's.verificar'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },


  paginationExcel(sort, direction, page, size, filters = {}){
    return `${path}/pagination/export-excel?sort=${sort || 'sl.solicitacao'},${direction || 'asc'}&page=${page || 1}&size=${size || 10}`;
  },

  async getSelosPendentes(inicio, fim, tipo){
    return Http.get(`${path}/selos-pendentes/${inicio}/${fim}/${tipo}`);
  },

  async getSelosComErro(inicio, fim){
    return Http.get(`${path}/selos-com-erro/${inicio}/${fim}`);
  },

  async getXML(id){
    return Http.get(`${path}/public/xml/${id}`);
  },

  async getJSON(id){
    return Http.get(`${path}/public/json/${id}`);
  },

  getTipoSeloPendente() {
    return [
      {id: 'TODOS', nome: 'Todos'},
      {id: 'CERTIDAO', nome: 'Certidão'},
      {id: 'BUSCA', nome: 'Busca'},
      {id: 'PRENOTACAO', nome: 'Prenotação'},
      {id: 'ATO', nome: 'Ato'},
    ]
  },

  removerSeloPendente(id){
    return Http.get(`${path}/remover-selo-pendente/${id}`);
  },

  relatorioSintetico(id) {
    return `${path}/${id}`;
  },

  async save(id, dto){
    return Http.post(`${path}/${id}`, dto);
  },

  async getQtdVinculoSicaseCertidao(sicase){
    return Http.get(`${path}/qtd-vinculo-sicase-certidao/${sicase}`);
  },

  async compararSeloArquivoTj(filtros,file){
    let seloPaginationFilter={
      filtros:filtros
    }

    const formData = new FormData();
    formData.append("seloPaginationFilter", JSON.stringify(seloPaginationFilter));
    formData.append("file", file);

    let config = {
      timeout: 0,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    return Http.post(`${path}/comparar-selo-arquivo-tj`,formData,config);
  }

}
