<template>
  <modal :titulo="titulo" id="simulacao-indicador-real" :loading="loading">
    <template #actions>
      <li class="list-inline-item">
        <a @click.prevent.stop="imprimir(ficha)" title="Imprimir" href>
          <i class="fal fa-print"></i>
        </a>
      </li>
    </template>

    <p v-if="loading">
      Processando atos: {{resumo.atual | inteiro}}/{{resumo.total | inteiro}}
    </p>

    <template v-if="fichaAtualizada">

      <AlertBannerRadius :alertas="alertas" classe="alert-danger" />
      <template v-for="(ato, i) in erros">
        <erro-validacao :ato="ato" :protocolo="protocolo" :key="ato.id + '-' + i" servico="IndicadorReal"
                        :exibeNomeServico="false" @abrirErro="abrirErro(...$event)" />
      </template>


      <card titulo="Situação" v-if="ficha.status != fichaAtualizada.status">
        <card-table class="table-borderless">
          <thead>
            <tr>
              <th width="33%">ANTES</th>
              <th width="33%">DEPOIS</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="">{{ficha.status}}</td>
              <td class=" pr-2">{{fichaAtualizada.status}}</td>
            </tr>
          </tbody>
        </card-table>
      </card>

      <card titulo="Registro Anterior" v-if="fichaAtualizada.registroAnterior != null && fichaAtualizada.registroAnterior.length > 0">
        <div class="row">
          <div class="col-6">
            <card-table class="table-borderless">

              <thead>
              <tr>
                <th class="pl-2 pb-0" colspan="2">ANTES</th>
              </tr>
              <tr>
                <th class="pl-2 w-25">Serventia</th>
                <th class="pl-2 w-25">Registro</th>
                <th class="pl-2 w-25">Livro</th>
                <th class="w-25">Folha</th>
              </tr>
              </thead>

              <tbody>
              <tr v-for="(campo, index) in fichaAtualizada.origens" :key="campo.descricao+'_'+index">
                <td class="pl-2">{{campo.serventia}}</td>
                <td class="pl-2">{{campo.registro}}</td>
                <td class="pl-2 ">{{campo.livroLetra == 'null' ? '-' : campo.livroLetra || '-'}}</td>
                <td class=" pr-2">{{campo.livroNumero == 'null' ? '-' : campo.livroNumero || '-'}}</td>
                <td class=" pr-2">{{campo.livroFolha == 'null' ? '-' : campo.livroFolha || '-'}}</td>
              </tr>
              <tr v-if="!fichaAtualizada.origens || !fichaAtualizada.origens.length">
                <td>-</td><td>-</td><td>-</td><td>-</td>
              </tr>
              </tbody>

            </card-table>

          </div>
          <div class="col-6">
            <card-table class="table-borderless">

              <thead>
              <tr>
                <th class="pl-2 pb-0" colspan="2">DEPOIS</th>
              </tr>
              <tr>
                <th class="pl-2 w-25">Serventia</th>
                <th class="pl-2 w-25">Registro</th>
                <th class="pl-2 w-25">Livro</th>
                <th class="w-25">Folha</th>
              </tr>
              </thead>

              <tbody>
              <tr v-for="(campo, index) in fichaAtualizada.registroAnterior" :key="campo.descricao+'_'+index">
                <td class="pl-2">{{campo.serventia}}</td>
                <td class="pl-2">{{campo.registro}}</td>
                <td class="pl-2 ">{{campo.livroLetra == 'null' ? '-' : campo.livroLetra || '-'}}</td>
                <td class=" pr-2">{{campo.livroNumero == 'null' ? '-' : campo.livroNumero || '-'}}</td>
                <td class=" pr-2">{{campo.livroFolha == 'null' ? '-' : campo.livroFolha || '-'}}</td>
              </tr>
              <tr v-if="!fichaAtualizada.registroAnterior || !fichaAtualizada.registroAnterior.length">
                <td>-</td><td>-</td><td>-</td><td>-</td>
              </tr>
              </tbody>

            </card-table>

          </div>
        </div>
      </card>



      <card titulo="Imóvel da União" v-if="ficha.imovelUniao !== fichaAtualizada.imovelUniao">
        <card-table class="table-borderless">

          <thead>
          <tr>
          <tr>
            <th>ANTES</th>
            <th>DEPOIS</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="">{{ficha.imovelUniao}}</td>
            <td class="">{{fichaAtualizada.imovelUniao}}</td>
          </tr>
          </tbody>
        </card-table>
      </card>

      <card titulo="Imóvel da Marinha" v-if="ficha.marinha !== fichaAtualizada.marinha">
        <card-table class="table-borderless">

          <thead>
          <tr>
          <tr>
            <th>ANTES</th>
            <th>DEPOIS</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="">{{ficha.marinha}}</td>
            <td class="">{{fichaAtualizada.marinha}}</td>
          </tr>
          </tbody>
        </card-table>
      </card>

      <card titulo="Imóvel de Fronteira" v-if="ficha.fronteira !== fichaAtualizada.fronteira">
        <card-table class="table-borderless">

          <thead>
          <tr>
          <tr>
            <th>ANTES</th>
            <th>DEPOIS</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="">{{ficha.fronteira}}</td>
            <td class="">{{fichaAtualizada.fronteira}}</td>
          </tr>
          </tbody>
        </card-table>
      </card>

      <card titulo="Identificação" v-if="mostrarIdentificacao">
        <card-table class="table-borderless">

          <thead>
            <tr>
              <th width="33%" class="pl-2">CAMPO</th>
              <th width="33%">ANTES</th>
              <th width="33%">DEPOIS</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(campo) in campos.identificacao">
              <tr :key="campo.nome" v-if="ficha[campo.id] != fichaAtualizada[campo.id]">
                <td class="pl-2">{{campo.nome}}</td>
                <td>{{ficha[campo.id] || '-'}}</td>
                <td class="pr-2">{{fichaAtualizada[campo.id] || '-'}}</td>
              </tr>
            </template>
          </tbody>

        </card-table>
      </card>

      <card titulo="Informações Complementares" v-if="mostrarInfo">
        <card-table class="table-borderless">

          <thead>
          <tr>
            <th width="33%" class="pl-2">CAMPO</th>
            <th width="33%">ANTES</th>
            <th width="33%">DEPOIS</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(campo) in campos.info">
            <tr :key="campo.nome" v-if="ficha[campo.id] != fichaAtualizada[campo.id]">
              <td class="pl-2">{{campo.nome}}</td>
              <td>{{exibicao(ficha, campo.id) || '-'}}</td>
              <td>{{exibicao(fichaAtualizada, campo.id) || '-'}}</td>
            </tr>
          </template>
          </tbody>

        </card-table>
      </card>

      <card titulo="Endereço" v-if="mostrarEndereco">
        <card-table class="table-borderless">

          <thead>
            <tr>
              <th width="33%" class="pl-2">CAMPO</th>
              <th width="33%">ANTES</th>
              <th width="33%">DEPOIS</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(campo) in campos.endereco">
              <tr :key="campo.nome" v-if="ficha[campo.id] != fichaAtualizada[campo.id]">
                <td class="pl-2">{{campo.nome}}</td>
                <td class="">{{ficha[campo.id] || '-'}}</td>
                <td class=" pr-2">{{fichaAtualizada[campo.id] || '-'}}</td>
              </tr>
            </template>
          </tbody>

        </card-table>
      </card>

      <card titulo="Área" v-if="mostrarArea">
        <card-table class="table-borderless">

          <thead>
            <tr>
              <th width="33%" class="pl-2">CAMPO</th>
              <th width="33%">ANTES</th>
              <th width="33%">DEPOIS</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(campo) in campos.area">
              <tr :key="campo.nome" v-if="ficha[campo.id] != fichaAtualizada.camposArea[campo.id]">
                <td class="pl-2">{{campo.nome}}</td>
                <td class="">{{ficha[campo.id] || '-'}}</td>
                <td class=" pr-2">{{fichaAtualizada.camposArea[campo.id] || '-'}}</td>
              </tr>
            </template>
          </tbody>

        </card-table>
      </card>

      <card titulo="Outras Áreas" v-if="mostrarAreaComum">

        <div class="row">
          <div class="col-6">
            <card-table class="table-borderless">

              <thead>
              <tr>
                <th class="pl-2 pb-0" colspan="2">ANTES</th>
              </tr>
              <tr>
                <th class="pl-2 w-25">Tipo</th>
                <th class="pl-2 w-50">Descrição</th>
                <th class="w-25">Área</th>
              </tr>
              </thead>

              <tbody>
              <tr v-for="(campo, index) in fichaAtualizada.areas" :key="campo.descricao+'_'+index">
                <td class="pl-2 ">{{campo.tipo}}</td>
                <td class="px-2 ">{{campo.descricao}}</td>
                <td class=" pr-2">{{$formatters.currency(campo.area,{min : 2, max : 10})}}</td>
              </tr>
              <tr v-if="!fichaAtualizada.areas || !fichaAtualizada.areas.length">
                <td>-</td><td>-</td><td>-</td>
              </tr>
              </tbody>

            </card-table>

          </div>
          <div class="col-6">
            <card-table class="table-borderless">

              <thead>
              <tr>
                <th class="pl-2 pb-0" colspan="2">DEPOIS</th>
              </tr>
              <tr>
                <th class="pl-2 w-25">Tipo</th>
                <th class="pl-2 w-50">Descrição</th>
                <th class="w-25">Área</th>
              </tr>
              </thead>

              <tbody>
              <tr v-for="(campo, index) in fichaAtualizada.camposAreaComum" :key="campo.descricao+'_'+index">
                <td class="pl-2 ">{{campo.tipo}}</td>
                <td class="px-2 ">{{campo.descricao}}</td>
                <td class=" pr-2">{{$formatters.currency(campo.area,{min : 2, max : 10})}}</td>
              </tr>
              <tr v-if="!fichaAtualizada.camposAreaComum || !fichaAtualizada.camposAreaComum.length">
                <td>-</td><td>-</td><td>-</td>
              </tr>
              </tbody>

            </card-table>

          </div>
        </div>

      </card>

      <card titulo="Áreas Edificadas" v-if="mostrarAreaEdificada">

        <div class="row">
          <div class="col-6">
            <card-table class="table-borderless">

              <thead>
              <tr>
                <th class="pl-2 pb-0" colspan="2">ANTES</th>
              </tr>
              <tr>
                <th class="pl-2 w-75">Descrição</th>
                <th class="w-25">Área</th>
              </tr>
              </thead>

              <tbody>
              <tr v-for="(campo, index) in fichaAtualizada.areasEdificadas" :key="campo.descricao+'_'+index">
                <td class="px-2 ">{{campo.descricao}}</td>
                <td class=" pr-2">{{$formatters.currency(campo.area,{min : 2, max : 10})}}</td>
              </tr>
              <tr v-if="!fichaAtualizada.areasEdificadas || !fichaAtualizada.areasEdificadas.length">
                <td>-</td><td>-</td>
              </tr>
              </tbody>

            </card-table>

          </div>
          <div class="col-6">
            <card-table class="table-borderless">

              <thead>
              <tr>
                <th class="pl-2 pb-0" colspan="2">DEPOIS</th>
              </tr>
              <tr>
                <th class="pl-2 w-75">Descrição</th>
                <th class="w-25">Área</th>
              </tr>
              </thead>

              <tbody>
              <tr v-for="(campo, index) in fichaAtualizada.camposAreaEdificada" :key="campo.descricao+'_'+index">
                <td class="px-2 ">{{campo.descricao}}</td>
                <td class=" pr-2">{{$formatters.currency(campo.area,{min : 2, max : 10})}}</td>
              </tr>
              <tr v-if="!fichaAtualizada.camposAreaEdificada || !fichaAtualizada.camposAreaEdificada.length">
                <td>-</td><td>-</td>
              </tr>
              </tbody>

            </card-table>

          </div>
        </div>

      </card>

      <card titulo="Unidades Acessórias" v-if="mostrarUnidadesAcessorias">

        <div class="row">
          <div class="col-6">
            <card-table class="table-borderless">

              <thead>
              <tr>
                <th class="pl-2 pb-0" colspan="2">ANTES</th>
              </tr>
              <tr>
                <th class="pl-2 w-25">Descrição</th>
                <th class="pl-2 w-25">Unidade</th>
                <th class="pl-2 w-25">Complemento</th>
                <th class="w-25">Área</th>
              </tr>
              </thead>

              <tbody>
              <tr v-for="(campo, index) in fichaAtualizada.unidadesAcessorias" :key="campo.descricao+'_'+index">
                <td class="px-2 ">{{campo.descricao}}</td>
                <td class="pr-2 ">{{campo.unidade}}</td>
                <td class="pr-2 ">{{campo.complemento}}</td>
                <td class=" pr-2">{{$formatters.currency(campo.area,{min : 2, max : 10})}}</td>
              </tr>
              <tr v-if="!fichaAtualizada.unidadesAcessorias || !fichaAtualizada.unidadesAcessorias.length">
                <td>-</td><td>-</td><td>-</td><td>-</td>
              </tr>
              </tbody>

            </card-table>

          </div>
          <div class="col-6">
            <card-table class="table-borderless">

              <thead>
              <tr>
                <th class="pl-2 pb-0" colspan="2">DEPOIS</th>
              </tr>
              <tr>
                <th class="pl-2 w-25">Descrição</th>
                <th class="pl-2 w-25">Unidade</th>
                <th class="pl-2 w-25">Complemento</th>
                <th class="w-25">Área</th>
              </tr>
              </thead>

              <tbody>
              <tr v-for="(campo, index) in fichaAtualizada.camposUnidadesAcessorias" :key="campo.descricao+'_'+index">
                <td class="px-2 ">{{campo.descricao}}</td>
                <td class="pr-2 ">{{campo.unidade}}</td>
                <td class="pr-2 ">{{campo.complemento}}</td>
                <td class=" pr-2">{{$formatters.currency(campo.area,{min : 2, max : 10})}}</td>
              </tr>
              <tr v-if="!fichaAtualizada.camposUnidadesAcessorias || !fichaAtualizada.camposUnidadesAcessorias.length">
                <td>-</td><td>-</td><td>-</td><td>-</td>
              </tr>
              </tbody>

            </card-table>

          </div>
        </div>

      </card>

      <card titulo="Confrontações" v-if="mostrarConfrontacoes">

        <div class="row">
          <div class="col-6">
            <card-table class="table-borderless">

              <thead>
              <tr>
                <th class="pl-2 pb-0" colspan="2">ANTES</th>
              </tr>
              <tr>
                <th class="pl-2">Confrontação</th>
                <th class="pl-2">Metragem</th>
                <th class="pl-2">Confrontante</th>
              </tr>
              </thead>

              <tbody>
              <tr v-for="(campo, index) in fichaAtualizada.confrontacoes" :key="campo.descricao+'_'+index">
                <td class="px-2 ">{{campo.confrontacao}}</td>
                <td class="pr-2 ">{{campo.metragem}}</td>
                <td class="pr-2 ">{{campo.confrontante}}</td>
              </tr>
              <tr v-if="!fichaAtualizada.confrontacoes || !fichaAtualizada.confrontacoes.length">
                <td>-</td><td>-</td><td>-</td><td>-</td>
              </tr>
              </tbody>

            </card-table>

          </div>
          <div class="col-6">
            <card-table class="table-borderless">

              <thead>
              <tr>
                <th class="pl-2 pb-0" colspan="2">DEPOIS</th>
              </tr>
              <tr>
                <th class="pl-2">Confrontação</th>
                <th class="pl-2">Metragem</th>
                <th class="pl-2">Confrontante</th>
              </tr>
              </thead>

              <tbody>
              <tr v-for="(campo, index) in fichaAtualizada.camposConfrontacoes" :key="campo.descricao+'_'+index">
                <td class="px-2 ">{{campo.confrontacao}}</td>
                <td class="pr-2 ">{{campo.metragem}}</td>
                <td class="pr-2 ">{{campo.confrontante}}</td>
              </tr>
              <tr v-if="!fichaAtualizada.camposConfrontacoes || !fichaAtualizada.camposConfrontacoes.length">
                <td>-</td><td>-</td><td>-</td><td>-</td>
              </tr>
              </tbody>

            </card-table>

          </div>
        </div>

      </card>

      <card titulo="Especificações do Imóvel" v-if="ficha.especificacao != fichaAtualizada.especificacao">
        <card-table class="table-borderless">

          <thead>
            <tr>
            <tr>
              <th>ANTES</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td v-html="ficha.especificacao"></td>
            </tr>
          </tbody>

          <thead>
            <tr>
            <tr>
              <th>DEPOIS</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td v-html="fichaAtualizada.especificacao"></td>
            </tr>
          </tbody>

        </card-table>
      </card>

      <card titulo="Proprietários" v-if="fichaAtualizada.proprietarios && fichaAtualizada.proprietarios.length > 0">

        <div class="row">
          <div class="col-6">
            <card-table class="table-borderless">

              <thead>
                <tr>
                  <th class="pl-2" colspan="2">ANTES</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(pessoa, index) in ficha.proprietarios" :key="'ficha_proprietario'+index">
                  <td class="pl-2">
                    <div>{{pessoa.indicadorPessoalVersao.nome}}</div>
                    <div class="text-muted">{{pessoa.indicadorPessoalVersao.documento}}</div>
                  </td>
                  <td class="text-right pr-2" width="150px">
                    <div>{{$formatters.currency(pessoa.fracao,{min : 2, max : 10})}}%</div>
                    <div v-if="informarAreaEnvolvidos">{{$formatters.currency(pessoa.area,{min : 2, max : 10})}} {{unidadeArea}}</div>
                  </td>
                </tr>
              </tbody>

            </card-table>

          </div>
          <div class="col-6">
            <card-table class="table-borderless">

              <thead>
                <tr>
                  <th class="pl-2" colspan="2">DEPOIS</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(pessoa, index) in fichaAtualizada.proprietarios"
                    :key="'ficha_atualizada_proprietarios'+index">
                  <td class="pl-2">
                    <div>{{pessoa.indicadorPessoalVersao.nome}}</div>
                    <div class="text-muted">{{pessoa.indicadorPessoalVersao.documento}}</div>
                  </td>
                  <td class="text-right pr-2" width="150px">
                    <div>{{$formatters.currency(pessoa.fracao,{min : 2, max : 10})}}%</div>
                    <div v-if="informarAreaEnvolvidos">{{$formatters.currency(pessoa.area || 0,{min : 2, max : 10})}} {{unidadeArea}}</div>
                  </td>
                </tr>
              </tbody>

            </card-table>

          </div>
        </div>

      </card>

      <card titulo="Pessoas com Indisponibilidade" v-if="ficha.indisponibilidades && ficha.indisponibilidades.length > 0">
        <card-content>
          <table class="table table-striped card-table table-vcenter">
            <thead>
            <tr>
              <th class="pl-1" width="100px">Nome</th>
              <th class="pl-1" width="100px">Documento</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(indisponibilidade, index) in ficha.indisponibilidades" :key="'ficha_indisponibilidade'+index">
              <td class="pr-2">{{indisponibilidade.nome}}</td>
              <td class=" pr-2">{{indisponibilidade.documento}}</td>
            </tr>
            </tbody>
          </table>
        </card-content>
      </card>

      <TabelaRestricoesAtivas :ficha="fichaAtualizada" indicadorReal simulacao/>

      <card titulo="Atos Praticados">
        <card-content>

          <template v-for="ato in fichaAtualizada.atos">

            <div :key="ato.id">

              <p class="mb-2" @dblclick="exportar(ato, ato.codigoStr)">
                <strong>{{ato.codigoStr}} {{ato.tipoServico.opcoes.titulo}}</strong>
              </p>

<!--              <div v-html="ato.minuta" v-dragscroll="true" class="texto-minuta mb-2 bg-sidebar-soft cursor-grab overflow-auto" v-if="ato.minuta"></div>-->
              <div v-html="ato.minuta" class="texto-minuta mb-2 bg-sidebar-soft overflow-auto" v-if="ato.minuta"></div>
              <div class="texto-minuta mb-5 text-red bg-sidebar-soft" v-else>
                Ato sem minuta configurada.
              </div>

            </div>

          </template>

        </card-content>
      </card>

    </template>

  </modal>
</template>

<script>
import AuthBusiness from "@/business/AuthBusiness.js";
import FrontBusiness from "@/business/FrontBusiness";
import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness.js";
import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness";
import OcorrenciaBusiness from "@/business/ocorrencia/OcorrenciaBusiness";
import AtoBusiness from "@/business/protocolo/AtoBusiness";
import ProtocoloDocumentoBusiness from "@/business/protocolo/ProtocoloDocumentoBusiness.js";
import IndicadorRealSimulacaoBusiness from "@/business/protocolo/simulacao/IndicadorRealSimulacaoBusiness";
import Utils from "@/commons/Utils";
import AlertBannerRadius from "@/components/alert/AlertBannerRadius";
import DetalhesIndicadorPessoal from "@/components/IndicadorPessoal/Detalhes/DetalhesIndicadorPessoal";
import DetalhesIndicadorReal from "@/components/IndicadorReal/Detalhes/DetalhesIndicadorReal";
import Card from "@/components/Layout/components/Card.vue";
import CardContent from "@/components/Layout/components/CardContent.vue";
import CardTable from "@/components/Layout/components/CardTable.vue";
import ImprimirFicha from "@/components/Livros/ImprimirFicha.vue";
import Modal from "@/components/Modal";
import TabelaRestricoesAtivas from "@/components/Restricao/TabelaRestricoesAtivas";
import ErroValidacao from "@/views/Protocolo/Atos/Simulacao/ErroValidacao.vue";
import CertidaoBusiness from "@/business/protocolo/CertidaoBusiness";
import OficioBusiness from "@/business/oficio/OficioBusiness";
import ExigenciaBusiness from "@/business/livros/ExigenciaBusiness";
import NotificacaoBusiness from "@/business/notificacao/NotificacaoBusiness";

export default {

    name: "SimularIndicadorReal",
    components: {TabelaRestricoesAtivas, AlertBannerRadius, CardTable, CardContent, Card, ErroValidacao, Modal},
    props: {
      id: String,
      protocolo: Object,
      ato: String,
      texto: String,
      modelo: String,
      tipoItem: {
        type: String,
        default: 'ATO'
      },
      indices: {
        type: Object,
        default: () => ({})
      }
    },

    modal: {
        width: 1100
    },

    data() {
      return {
        loading: false,
        ficha: {},
        fichaAtualizada: null,
        erros: [],
        alertas: [],

        campos: {
          info: [],
          identificacao: [],
          area: [],
          endereco: [],
        },
        resumo : {
          total : 0,
          atual : 0,
          inicio : this.$moment(),
          fim : null
        }

      }
    },

    computed: {

      titulo() {
        return `Simular Resultado: ${FrontBusiness.nomearFicha(this.ficha)}`
      },

      mostrarArea() {
        return this.fichaAtualizada.camposArea != undefined && Object.keys(this.fichaAtualizada.camposArea)?.length
      },

      mostrarAreaEdificada() {
          return this.fichaAtualizada.camposAreaEdificada != undefined && Object.keys(this.fichaAtualizada.camposAreaEdificada)?.length
      },

      mostrarAreaComum() {
          return this.fichaAtualizada.camposAreaComum != undefined && Object.keys(this.fichaAtualizada.camposAreaComum)?.length
      },

      mostrarUnidadesAcessorias(){
        return this.fichaAtualizada.camposUnidadesAcessorias != undefined && Object.keys(this.fichaAtualizada.camposUnidadesAcessorias)?.length
      },

      mostrarConfrontacoes(){
        return this.fichaAtualizada.camposConfrontacoes != undefined && Object.keys(this.fichaAtualizada.camposConfrontacoes)?.length
      },

      mostrarEndereco() {
        return this.campos.endereco.find(campo => this.ficha[campo.id] != this.fichaAtualizada[campo.id]);
      },

      mostrarInfo() {
        return this.campos.info.find(campo => this.ficha[campo.id] != this.fichaAtualizada[campo.id]);
      },

      mostrarIdentificacao() {
        return this.campos.identificacao.find(campo => this.ficha[campo.id] != this.fichaAtualizada[campo.id]);
      },

      unidadeArea(){
        return this.ficha.medidaArea ? FrontBusiness.getLabel(this.ficha.medidaArea, IndicadorRealBusiness.getMedidas()) : '';
      },

      informarAreaEnvolvidos(){
        let area = 0;
        let ato = this.fichaAtualizada?.atos?.find( a => a?.dto?.informarAreaEnvolvidos) || {};
        if (ato) {
          (ato?.envolvidos || []).map(e => area += e?.area || 0)
        }
        return ato && area > 0;
      },

    },

    beforeMount() {
      this.load();
    },

    methods: {

      exibicao(ficha, id) {
        if (id === 'numeroRipSpu') {
          return this.$formatters.numeroRipSpu(ficha[id])
        }
        return ficha[id]
      },

      async load() {

        this.loading = true;

        let documentos = [], itens = [], p1, p2, p3;

        IndicadorPessoalBusiness.resetCache();

        if(this.id) {
          p1 = IndicadorRealBusiness.getById(this.id).then(f => this.ficha = f);
        }

        if (this.protocolo?.id) {
          p2 = ProtocoloDocumentoBusiness.listar(this.protocolo.id).then(f => documentos = f);
        }

        let usuarioLogado = this.$root.usuarioLogado;

        let business;
        switch (this.tipoItem) {
          case 'ATO':
            if (this.protocolo?.id) {
              p3 = AtoBusiness.listarPorFicha(this.protocolo.id, 'indicador-real', this.id).then(f => itens = f);
              business = AtoBusiness;
            }
            break;
          case 'CERTIDAO':
            if (this.protocolo?.id) {
              p3 = CertidaoBusiness.listar(this.protocolo.id).then(f => itens = f);
              business = CertidaoBusiness;
            }
            break;
        }

        await Promise.all([p1, p2, p3]);

        if (!itens.length && this.ato) {
          let ato = await business.getById(null, this.ato);
          ato.envolvidos.forEach(e => e.indicadorPessoalVersaoId = e.indicadorPessoalVersao.id);
          itens = [ato];
        }

        this.resumo.total = itens.length;

        if(this.ato || (['OFICIO', 'EXIGENCIA', 'NOTIFICACAO'].includes(this.tipoItem))){
          let result;

          switch (this.tipoItem) {
            case 'ATO':
              result = await IndicadorRealSimulacaoBusiness.gerarMinuta(Utils.clone(this.protocolo), Utils.clone(this.ficha), itens, documentos, this.resumo, {id: this.ato, texto : this.texto}, usuarioLogado);
              let ato = result.atos.find(a => a.id == this.ato);
              FrontBusiness.closeModal(ato);
              break;
            case 'CERTIDAO':
              result = await CertidaoBusiness.gerarMinuta(this.protocolo.id, this.ato, this.modelo);
              FrontBusiness.closeModal(result);
              break;
            case 'OFICIO':
              result = await OficioBusiness.gerarMinuta(this.protocolo.id, this.modelo);
              FrontBusiness.closeModal(result);
              break;
            case 'NOTIFICACAO':
              result = await NotificacaoBusiness.gerarMinuta(this.protocolo.id, this.modelo);
              FrontBusiness.closeModal(result);
              break;
            case 'EXIGENCIA':
              result = await ExigenciaBusiness.copilarTextoExigencia(this.protocolo, this.texto, this.indices);
              FrontBusiness.closeModal(result);
              break;
          }

          return;
        }

        let result = await IndicadorRealSimulacaoBusiness.simular(Utils.clone(this.protocolo), Utils.clone(this.ficha), itens, documentos, this.resumo, usuarioLogado);

        this.resumo.fim =  this.$moment();

        this.fichaAtualizada = result.ficha;
        this.ficha.imovelUniao = !Utils.isEmptyNullOrUndefined(this.ficha.imovelUniao) ? this.ficha.imovelUniao ? 'Sim' : 'Não' : '-';
        this.ficha.marinha = !Utils.isEmptyNullOrUndefined(this.ficha.marinha) ? this.ficha.marinha ? 'Sim' : 'Não' : '-';
        this.ficha.fronteira = !Utils.isEmptyNullOrUndefined(this.ficha.fronteira) ? this.ficha.fronteira ? 'Sim' : 'Não' : '-';
        this.fichaAtualizada.imovelUniao = !Utils.isEmptyNullOrUndefined(this.fichaAtualizada.imovelUniao) ? this.fichaAtualizada.imovelUniao ? 'Sim' : 'Não' : '-';
        this.fichaAtualizada.marinha = !Utils.isEmptyNullOrUndefined(this.fichaAtualizada.marinha) ? this.fichaAtualizada.marinha ? 'Sim' : 'Não' : '-';
        this.fichaAtualizada.fronteira = !Utils.isEmptyNullOrUndefined(this.fichaAtualizada.fronteira) ? this.fichaAtualizada.fronteira ? 'Sim' : 'Não' : '-';
        this.fichaAtualizada.atos = result.atos;

        this.erros = result.atos.filter(a => a.erros.length);

        const ocorrenciasAtivas = await OcorrenciaBusiness.indicadorRealPossuiOcorrenciasAtivas(this.ficha.id);

        if(ocorrenciasAtivas){
          this.criarAlerta(FrontBusiness.nomearFicha(this.ficha)+" possui ocorrências", () => {
            FrontBusiness.openModal(DetalhesIndicadorReal, {id: this.ficha.id, abaInicial: 'ocorrencias'});
          });
        }

        this.campos.info = this.getCamposInfoComplementar(this.fichaAtualizada.categoria, this.fichaAtualizada.imovelUniao);
        this.campos.identificacao = this.getCamposCadastroIdentificacao(this.fichaAtualizada.categoria);
        this.campos.endereco = this.getCamposEndereco();
        this.campos.area = this.getCamposArea(this.fichaAtualizada.unidadeAutonoma);

        this.loading = false;
      },

      criarAlerta(msg, command) {
        this.alertas.push({msg, command});
      },

      exportar(ato, name) {
        console.debug('debug', name, ato.debug);
        const texto = Utils.stringify(ato.debug);
        console.debug('debug', name, texto);
      },

      detalhes(pessoa) {
        FrontBusiness.openModal(DetalhesIndicadorPessoal, {id: pessoa.indicadorPessoalId, versao : pessoa.id});
      },

      getCamposEndereco() {
        return [
          {id: 'cep', tipo: 'cep', nome: 'CEP'},
          {id: 'tipoLogradouro', tipo: 'text', nome: 'Tipo Logradouro'},
          {id: 'logradouro', tipo: 'text', nome: 'Logradouro (Rua)'},
          {id: 'numero', tipo: 'text', nome: 'Número'},
          {id: 'bairro', tipo: 'text', nome: 'Bairro (Setor)'},
          {id: 'cidade', tipo: 'text', nome: 'Cidade'},
          {id: 'estado', tipo: 'select', nome: 'Estado'},
          {id: 'complemento', tipo: 'text', nome: 'Complemento'},
        ];
      },

      getCamposArea(categoria) {

        if (categoria) {
            return [
                {id: 'medidaArea', tipo: 'text', nome: 'Medida Área'},
                {id: 'area', tipo: 'text', nome: 'Área Total'},

                {id: 'fracaoSolo', tipo: 'text', nome: 'Fração Solo'},
                {id: 'fracaoSoloPercentual', tipo: 'text', nome: 'Fração Solo (%)'},
                {id: 'coeficienteProporcionalidade', tipo: 'text', nome: 'Coeficiente de proporcionalidade'},
            ];
        } else {
            return [
                {id: 'medidaArea', tipo: 'text', nome: 'Medida Área'},
                {id: 'area', tipo: 'text', nome: 'Área Total'},
                {id: 'medidaAreaDoi', tipo: 'text', nome: 'Medida Área DOI'},
                {id: 'areaTotalDoi', tipo: 'text', nome: 'Área total DOI'},
            ];
        }
      },

      getCamposInfoComplementar(categoria, imovelUniao) {
        let retorno = [];
        if (categoria != 'RURAL' && categoria != '') {
          retorno = [
            {id: 'andar', tipo: 'text', nome: 'Andar'},
            {id: 'pavimento', tipo: 'text', nome: 'Pavimento'},
            {id: 'grupo', tipo: 'text', nome: 'Grupo (Tipo)'},
            {id: 'cadastroImobiliario', tipo: 'text', nome: 'Cadastro Imobiliário'},
            {id: 'cadastroImobiliarioBrasileiro', tipo: 'text', nome: 'Cadastro Imobiliário Brasileiro (CIB)'},
          ];
        } else {
          retorno = [
            {id: 'andar', tipo: 'text', nome: 'Andar'},
            {id: 'pavimento', tipo: 'text', nome: 'Pavimento'},
            {id: 'grupo', tipo: 'text', nome: 'Grupo (Tipo)'},
            {id: 'denominacao', tipo: 'text', nome: 'Denominação da Área Rural'},
            {id: 'incra', tipo: 'text', nome: 'INCRA (CCIR)'},
            {id: 'itr', tipo: 'text', nome: 'ITR'},
            {id: 'car', tipo: 'text', nome: 'CAR'},
            {id: 'certificacaoIncra', tipo: 'text', nome: 'Certificação no SIGEF'},
            {id: 'cadastroImobiliario', tipo: 'text', nome: 'Cadastro Imobiliário'},
            {id: 'cadastroImobiliarioBrasileiro', tipo: 'text', nome: 'Cadastro Imobiliário Brasileiro (CIB)'},
          ];
        }

        if (imovelUniao) {
          retorno.push({id: 'numeroRipSpu', tipo: 'mask', nome: 'Número RIP SPU'})
        }

        return retorno;
      },

      getCamposCadastroIdentificacao(categoria) {
        let campos = [
          {id: 'unidade', tipo: 'text', nome: 'Número da Unidade'},
          {id: 'bloco', tipo: 'text', nome: 'Bloco'},
          {id: 'quadra', tipo: 'text', nome: 'Quadra'},
          {id: 'lote', tipo: 'text', nome: 'Lote'},
          {id: 'localizacao', tipo: 'text', nome: 'Localização'},
          {id: 'empreendimento', tipo: 'text', nome: 'Empreendimento/Condomínio'},
        ]

        // if (categoria === 'RURAL') {
        //   campos =  [ ...campos, ...[
        //     {id: 'quadra', tipo: 'text', nome: 'Quadra'},
        //     {id: 'lote', tipo: 'text', nome: 'Lote'},
        //     {id: 'localizacao', tipo: 'text', nome: 'Localização'},
        //     {id: 'empreendimento', tipo: 'text', nome: 'Empreendimento'},]
        //   ];
        // } else if (categoria === 'URBANO') {
        //   campos =  [ ...campos, ...[
        //     {id: 'quadra', tipo: 'text', nome: 'Quadra'},
        //     {id: 'lote', tipo: 'text', nome: 'Lote'},
        //     {id: 'localizacao', tipo: 'text', nome: 'Localização'},
        //     {id: 'empreendimento', tipo: 'text', nome: 'Empreendimento'},
        //   ]
        //   ]
        // }

        return campos
      },

      async abrirErro(ato, areaErroSimulacao) {
        let atoId = ato.id != null ? ato.id : ato.atoId;
        FrontBusiness.closeModal({id: atoId, area: areaErroSimulacao});
      },

      async imprimir(ficha){
        FrontBusiness.openModal(ImprimirFicha, {id: ficha.id, tipo: ficha.livro, codigo: ficha.codigo,
          simulacao: true, atosSimulacao: this.fichaAtualizada.atos});
      },

    }

  }
</script>

<style lang=scss>
  .text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
